<template>
    <div class="out-wrap" @click="jump2GoodDetail">
        <div class="wrap" @mouseenter="showDelete" @mouseleave="hideDelete">
            <div class="group" v-if="good.groupBuy">
                <span class="text">拼团</span>
            </div>
            <div class="icon-wrap" 
                 @click.stop="handleDelete" 
                 :class="{active:isActive}"
            >
                <i class="el-icon-delete" />
            </div>
            <div class="img-wrap">
                <img :src="good.goodsImg" alt="">
            </div>
            <div class="text-wrap">
                <div class="commission">
                    预估佣金：<span class="number">&yen;
                        {{ good.commissionAmount }}</span>
                    <span class="rate">({{ good.commissionRate * 100 }}%)</span>
                </div>
                <div class="price">价格：&yen;{{ good.salePrice }}</div>
                <div class="title">{{ good.goodsTitle }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'exportGoods',
    data() {
        return {
            isActive: false
        };
    },
    props: {
        good: {
            type: Object,
            deefault: (() => {})
        }
    },
    methods: {
        showDelete() {
            this.isActive = true;
        },
        hideDelete() {
            this.isActive = false;
        },
        handleDelete() {
            this.$emit('delteGoods');
        },
        jump2GoodDetail() {
            window.open(`https://goods.kaola.com/product/${this.good.goodsId}.html`);
        }
    }
};
</script>

<style lang='scss' scoped>
.out-wrap {
    flex-shrink: 0;
    margin-right: 16px;
    margin-bottom: 16px;
    display: inline-block;
    width: 306px;
}
.wrap {
    position: relative;
    width: 306px;
    height: 103px;
    background: #fff;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    &:hover {
        cursor: pointer;
    }
    .group {
        position: absolute;
        top: 0;
        left: 0;
        width: 33px;
        height: 18px;
        line-height: 18px;
        background: #ff1e32;
        border-radius: 6px 0 6px 0;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        .text {
            width: 28px;
            height: 18px;
        }
    }
    .icon-wrap {
        position: absolute;
        top: 3px;
        right: 5px;
        display: none;
    }
    .active {
        display: block;
    }
    .img-wrap {
        width: 101px;
        height: 101px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px 0 0 5px;
        }
    }
    .text-wrap {
        margin: 10px 0;
        margin-left: 11px;
        display: flex;
        flex-direction: column;
        .commission {
            font-size: 14px;
            color: #333;
            font-weight: 700;
            .number {
                color: #ff1e32;
                font-weight: 500;
            }
            .rate{
                margin-left: 5px;
                color: #2a2a2a;
                font-weight:300;
            }
        }
        .price {
            padding-top: 4px;
            font-size: 12px;
            color: #333;
        }
        .title {
            padding-top: 25px;
            width: 185px;
            color: #333;
            font-size: 12px;
            width: 150px;
            white-space: nowrap;      
            overflow: hidden;        
            text-overflow: ellipsis;
        }
    }
}
</style>
<template>
    <div class="wrap goods-wrap"
         @mouseleave="hideCheck" @mouseover="showsingleCheck"
         @click="jumpToGoodDetail"
    >
        <div class="check" ref="checkbox" @click.stop="">
            <el-checkbox @change="selectGoods" v-model="checked" :disabled="disabled" />
        </div>
        <div class="img-wrap">
            <img v-lazy="item.goodsImg " alt="">
        </div>
        <div class="title">{{ item.goodsTitle }}</div>
        <div class="price-wrap" v-if="isGroup !== &quot;22222&quot;">
            <div class="current-price">&yen;{{ item.salePrice }}</div>
            <div class="original-price" v-if="item.suggestPrice">&yen;{{ item.suggestPrice }}</div>
            <div class="mm">{{ item.bizType === 1 ? '猫享自营' : '考拉海购' }}</div>
        </div>
        <div class="price-wrap" v-if="isGroup === &quot;22222&quot;">
            <div class="current-price">拼团&yen;{{ item.salePrice }}</div>
            <div class="original-price by-group" v-if="item.suggestPrice">单买&yen;{{ item.suggestPrice }}</div>
            <div class="mm">{{ item.bizType === 1 ? '猫享自营' : '考拉海购' }}</div>
        </div>

        <div class="commission-wrap">
            <div class="commission-inner">
                <div class="left-wrap">
                    <div class="number">&yen;{{ item.commissionAmount }}</div>
                    <div class="commission">预估佣金</div>
                </div>
                <div class="divider" />
                <div class="right-wrap">
                    <div class="number">{{ formatRate(item.commissionRate) }}%</div>
                    <div class="commission">佣金比例</div>
                </div>
            </div>
        </div>
        <el-popover
            placement="top"
            width="400"
            trigger="click"
            :disabled="positionFlag"
            popper-class="pub-popover"
            v-if="item.bizType != 2"
        >
            <select-position
                @hidePosition="hidePosition"
                v-if="singleposition"
                @confirmPosition="confirmPosition"
            />
            <div class="button-wrap" slot="reference" ref="button" @click.stop="showPosition">
                立即推广
            </div>
        </el-popover>
    </div>
</template>

<script>
import { mapState} from 'vuex';
import Bus from '../../utils/bus.js';
//import storage from '../../utils/storage.js';
import sessionObj from '../../utils/sessionObj.js';
import selectPosition from './selectPromotionPosition.vue';
import thumbnail from '../../utils/thumbnail.js';
import formatRate from '../../utils/format-rate';

export default {
    name: 'goodsModel',
    components: {
        selectPosition
    },
    data() {
        return {
            checked: false,
            selectFlag: false,
            disabled: false,
            positionFlag: false,
            singleposition: false,
            checkboxFlag: false,
            groupTye: 1
        };
    },
    computed: {
        ...mapState([
            'selectedId',
            'selectedGoods'
        ])
    },
    watch: {
        selectedId() {
            if(this.selectedId.length > 0) {
                this.showAllCheck();
                //this.checkboxFlag = true;
            }
            if(this.selectedId.length === 0) {
                this.hideAllCheck();
                //this.checkboxFlag = false;
            }
        }
    },
    props: {
        item: {
            type: Object,
            default: (() => {})
        },
        isGroup: {
            type: String
        },
        bizType: {
            type: Number,
            default: null
        }
    },
    created() {
        if(this.isGroup === '22222') {
            Object.assign(this.item, {groupBuy: true});
        }
        if(sessionObj.getStore('selectedId')) {
            this.$nextTick(() => {
                if(this.selectedId.length > 0) {
                    this.showAllCheck();
                    sessionObj.getStore('selectedId').forEach((value) => {
                        if(this.item.goodsId === value) {
                            this.checked = true;
                        }
                    });
                }
            });
        }
        Bus.$on('deleteCheck', (id) => {
            this.$nextTick(() => {
                this.deleteCheck(id);
            });
        });
        Bus.$on('overNumber', (id) => {
            if(id === this.item.goodsId) {
                this.checked = false;
                this.disabled = true;
            }
        });
        this.item.goodsImg = thumbnail(this.item.goodsImg, {
            width: 440,
            height: 440

        });

    },
    // destroyed () {
    //     Bus.$off('deleteCheck');
    //      Bus.$off('overNumber');
    // },
    methods: {
        showPosition() {
            this.singleposition = true;
        },
        hidePosition() {
            this.$refs.button.click();
        },
        async confirmPosition(e) {
            this.$refs.button.click();
            const data = await this.$request({
                url: '/api/goodsPromotion/instantPromotionGoods',
                params: {
                    goodsId: this.item.goodsId,
                    groupBuyGoodsId: this.item.groupBuyGoodsId || '',
                    positionId: e
                }
            });


            if(this.item.groupBuyGoodsId) {
                this.groupTye = 2;
            }
            Bus.$emit('showDialog', {
                data,
                groupTye: this.groupTye
            });
        },
        selectGoods() {
            Bus.$emit('fn', this.getParams());
        },
        getParams() {
            return {
                checked: this.checked,
                goodsId: this.item.goodsId,
                goodsItem: this.item
            };
        },
        showsingleCheck(e) {
            e.currentTarget.childNodes[0].style.display = 'block';
        },
        showAllCheck() {
            if(this.$refs.checkbox){
                this.$refs.checkbox.style.display = 'block';
                this.selectFlag = true;
            }
        },
        hideAllCheck() {
            this.checked = false;
            if(this.$refs.checkbox){
                this.selectFlag = false;
                this.$refs.checkbox.style.display = 'none';
            }
        },
        hideCheck(e) {
            if(!this.selectFlag) {
                e.target.childNodes[0].style.display = 'none';
            }
        },
        deleteCheck(id) {
            if(id === this.item.goodsId) {
                this.checked = false;
            }
        },
        jumpToGoodDetail() {
            const link = `https://goods.kaola.com/product/${this.item.goodsId}.html`;
            window.open(link);
        },
        formatRate
    }
};
</script>

<style lang="scss">
    .pub-popover {
        height: 274px;
    }
</style>
<style lang='scss' scoped>
.wrap {
    width: 220px;
    height: 413px;
    border-radius: 8px;
    border: 0 solid #E6E6E6;
    background: #fff;
    margin-right: 16px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    .check {
        position: absolute;
        top: 8px;
        right: 10px;
        display: none;
    }
    .button-wrap {
        width: 190px;
        height: 40px;
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ff0000;
        border-radius: 20px;
        font-weight: 700;
        color: #ff0000;
        &:hover {
            color: #fff;
            background: #ff0000;
            border: 1px solid #ff0000;
        }
    }
    .img-wrap {
        width: 220px;
        height: 220px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 6px 6px 0 0;
        }
    }
    .title {
        margin-top: 8px;
        margin-bottom: 3px;
        padding: 0 14px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        line-height: 24px;
    }
    .price-wrap {
        padding: 0 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        .current-price {
            font-weight: 700;
            color: #333;
            height: 20px;
            line-height: 20px;
            font-size: 13px;
        }
        .original-price {
            margin-left: 5px;
            text-decoration: line-through;
            color: #999;
            height: 20px;
            line-height: 20px;
            font-size: 13px;
        }

        .mm {
            height: 20px;
            line-height: 20px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #999999;
            position: absolute;
            right: 20px;
            top: 0;
        }
        .by-group {
            margin-left: 15px;
            text-decoration: none;
        }
    }

    .commission-wrap {
        padding: 0 14px;
        height: 62px;
        margin-top: 10px;
        .commission-inner  {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFF8F8;
            border-radius: 3px;
            height: 100%;
            .divider {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 12px;
                bottom: 10px;
                width: 1px;
                background: #e3e3e3;
            }
        }
        .left-wrap, .right-wrap {
            width: 101px;
            height: 49px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .number {
                font-size: 20px;
                font-weight: 700;
                color: #ff1e32;
                line-height: 28px;
            }
            .commission {
                color: #666;
                font-size: 12px;
            }
        }
    }
    .split-line {
        position: absolute;
        left: 92px;
        top: 247px;
        height: 30px;
        width: 1px;
        background: #e3e3e3;
    }
}
</style>
<style lang="scss">
    .goods-wrap {
        .el-checkbox__inner {
            width: 20px;
            height: 20px;
            &::after {
                height: 12px;
                left: 7px;
            }
        }
    }
    .check {
        .el-checkbox__inner::after {
            box-sizing: content-box;
            content: "";
            border: 2px solid #FFFFFF;
            border-left: 0;
            border-top: 0;
            height: 10px;
            left: 5px;
            position: absolute;
            top: 1px;
            transform: rotate(45deg) scaleY(0);
            width: 5px;
            transform-origin: center;
        }
    }
</style>

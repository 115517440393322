<template>
    <div class="wrap">
        <div class="top">
            <span @click="close"><i class="el-icon-close" /></span>
        </div>
        <div class="remark">
            确定要删除已选商品？
        </div>
        <div class="bottom">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="deleteInfo">确定</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'deleteModel',
    methods: {
        close() {
            this.$emit('hideDialog');
        },
        deleteInfo() {
            this.$emit('confirmDelete');
        }
    }
};
</script>

<style lang="scss" scoped>
.wrap {
    width: 350px;
    height: 180px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .top {
        margin-top: 16px;
        margin-left: 315px;
        font-size: 16px;
        font-weight: 700;
    }
    .remark {
        text-align: center;
        margin-top: 25px;
        font-size: 16px;
    }
    .bottom {
        margin-top: 40px;
        margin-left: 180px;
    }
}
</style>

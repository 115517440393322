<template>
    <div class="wrap">
        <transition name="slide-fade">
            <div class="title-wrap" :class="{unfold: collapseFlag}" v-show="!foldFlag">
                <div class="text">{{ selectedId.length }}/50(一次最多选择50个商品)</div>
                <div class="button-wrap" @click="isDeleteAll">
                    <el-button size="small" class="delete-btn">
                        <i class="el-icon-delete" />清空所选
                    </el-button>
                </div>
            </div>
        </transition>
        <transition name="out-fade">
            <div class="middle-wrap" :class="{unfold: collapseFlag}" v-show="!foldFlag" />
        </transition>
        <transition name="slide-fade">
            <div class="inner-wrap" v-show="!foldFlag" :class="{unfold: collapseFlag}">
                <div class="list-wrap">
                    <exportGoods
                        @delteGoods="delteGoods(good)"
                        :good="good"
                        v-for="good in renderGoods"
                        :key="good.goodsId"
                    />
                    <div class="wrap" v-for="n in 20" :key="n" />
                </div>
            </div>
        </transition>

        <div class="bottom-wrap" :class="{unfold: collapseFlag}" v-show="layerFlag" @click="handleFold">
            <div class="text">
                已选商品<span class="number">{{ selectedId.length }}</span>个
                <i class="el-icon-arrow-up" v-if="foldFlag" />
                <i class="el-icon-arrow-down" v-if="!foldFlag" />
            </div>
            <el-popover
                placement="left"
                width="400"
                trigger="click"
                :disabled="positionFlag"
                popper-class="pub-popover"
            >
                <select-position
                    @hidePosition="hidePosition"
                    v-if="singleposition"
                    @confirmPosition="confirmPosition"
                    :export="true"
                />
                <div class="button-wrap" slot="reference" ref="button" @click.stop="showPosition">
                    <el-button type="primary" class="button">一键导出所有链接</el-button>
                </div>
            </el-popover>
        </div>

        <delete-dialog v-if="deleteFlag" @hideDialog="hideDialog" @confirmDelete="confirmDelete" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import exportGoods from './exportGoods.vue';
import deleteDialog from './deleteDialog.vue';
import Bus from '../../utils/bus.js';
import storage from '../../utils/storage.js';
import sessionObj from '../../utils/sessionObj.js';
import selectPosition from './selectPromotionPosition.vue';

export default {
    name: 'unfoldLayer',
    components: {
        exportGoods,
        deleteDialog,
        selectPosition
    },
    data() {
        return {
            foldFlag: true,
            deleteId: '',
            deleteFlag: false,
            positionFlag: false,
            singleposition: false,
            goodId: '',
            deleteType: 1,
            renderGoods: [],
            collapseFlag: false
        };
    },
    watch: {
        selectedId() {
            if(this.selectedId.length>50) {
                this.$message.error('最多只能选择50');
                Bus.$emit('overNumber', this.goodsId);
                this.deleteSelectedId({
                    id: this.goodsId,
                    allFlag: false
                });
                this.deleteSelectedGoods({
                    id: this.goodsId,
                    allFlag: false
                });
                sessionObj.setStore('selectedId', this.selectedId);
                sessionObj.setStore('selectedGoods', this.selectedGoods);
            }
        }
    },
    created() {
        Bus.$on('changeCollapse', () => {
            this.collapseFlag = !this.collapseFlag;
        });
    },
    mounted() {
        if(sessionObj.getStore('selectedId')) {
            this.getStoreId();
            this.getStoreGoods();
        }
        Bus.$on('fn', (params) => {
            this.updateData(params);
        });
    },
    destroyed() {
        Bus.$off('fn');
    },
    computed: {
        layerFlag() {
            if(this.selectedId && this.selectedId.length > 0) {
                return true;
            }
            return false;
        },
        ...mapState([
            'selectedId',
            'selectedGoods'
        ])
    },
    methods: {
        ...mapMutations(['getStoreId', 'getStoreGoods', 'pushSelectedId', 'pushSelectedGoods', 'deleteSelectedId', 'deleteSelectedGoods']),
        showPosition() {
            this.singleposition = true;
        },
        hidePosition() {
            this.$refs.button.click();
        },
        async confirmPosition(e) {
            const data = await this.$request({
                url: '/api/goodsPromotion/exportPromotionGoodsData',
                method: 'post',
                data: {
                    goodsId: this.selectedId,
                    positionId: e.positionId,
                    posiName: e.posiName,
                    goodsList: this.selectedGoods
                }
            });
            this.$refs.button.click();
            location.href=data.url;
        },
        delteGoods(good) {
            this.deleteType = 1;
            this.deleteId = good.goodsId;
            this.renderGoods.forEach((value, index, arr) => {
                if(value.goodsId === this.deleteId) {
                    arr.splice(index, 1);
                }
            });
            this.deleteStore(this.deleteId);
            Bus.$emit('deleteCheck', this.deleteId);
            //this.deleteFlag = false;
            if(sessionObj.getStore('selectedId').length === 0) {
                this.deleteAll();
            }
            //this.deleteFlag = true;
        },
        isDeleteAll() {
            this.deleteType = 2;
            this.deleteFlag = true;
        },
        hideDialog() {
            this.deleteFlag = false;
        },
        confirmDelete() {
            if(this.deleteType === 2) {
                this.deleteAll();
                this.deleteFlag = false;
            }
        },
        deleteAll() {
            this.renderGoods=[];
            this.deleteSelectedId({
                id: '',
                allFlag: true
            });
            this.deleteSelectedGoods({
                id: '',
                allFlag: true
            });
            storage.remove('selectedId');
            storage.remove('selectedGoods');
            this.foldFlag = true;
        },
        handleFold() {
            if(this.foldFlag) {
                this.changeFold();
            }else {
                this.unFold();
            }
        },
        changeFold() {
            this.foldFlag = false;
            this.renderGoods = this.selectedGoods;
        },
        unFold() {
            this.foldFlag = true;
        },
        deleteStore(delId) {
            this.deleteSelectedId({
                id: delId,
                allFlag: false
            });
            this.deleteSelectedGoods({
                id: delId,
                allFlag: false
            });
            sessionObj.setStore('selectedId', this.selectedId);
            sessionObj.setStore('selectedGoods', this.selectedGoods);
        },
        /* eslint-disable */
        updateData(params) {
            const goodsId = params.goodsId;
            this.goodsId = goodsId;
            const item = params.goodsItem;
            const checked = params.checked;
            if(checked) {
                this.pushSelectedId(goodsId);
                sessionObj.setStore('selectedId', this.selectedId);
                this.pushSelectedGoods(item);
                sessionObj.setStore('selectedGoods', this.selectedGoods);
            }
            if(!checked) {
                this.deleteStore(goodsId);
            }
        }
        /* eslint-disable */
    }
};
</script>

<style lang="scss">
    .pub-popover {
        height: 274px;
    }
</style>
<style>
.out-fade-enter-active {
  animation: fade-in .5s  -0.5s;
}
.out-fade-leave-active {
  animation: fade-in .5s .5s reverse;
}

.slide-fade-enter-active {
  animation: fade-in .5s ;
}
.slide-fade-leave-active {
  animation: fade-in  .5s reverse;
}

@keyframes fade-in {
 from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
        transform: translate3d(0, 2000px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}


</style>
<style lang='scss' scoped>

.wrap {
    .title-wrap {
        position: fixed;
        left: 200px;
        right: 0;
        background: #f9f9f9;
        bottom: 380px;
        height: 50px;
        z-index: 999;

        .text {
            cursor: pointer;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        .button-wrap {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            right: 20px;
            .delete-btn {
                border-radius: 32px;

                display: flex;
                align-items: center;
            }
        }
    }
    .bottom-wrap {
        position: fixed;
        left: 200px;
        bottom: 0;
        right:0;
        height: 60px;
        background: #fff;
        z-index: 999;
        border: 1px solid #ccc;
        .text {
            cursor:pointer;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .number {
                color: rgb(255, 86, 86);
            }
        }
        .button-wrap {
            width: 197px;
            height: 40px;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            right: 20px;
            .button {
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #FF1E32 0%, #FF3264 100%);
                border-radius: 21px;
            }
        }
    }
    .middle-wrap {
        position: fixed;
        top: 70px;
        left: 200px;
        right: 0px;
        bottom: 0px;
        background: rgba(0,0,0,.4);
        z-index: 111;
        overflow: hidden;
    }
    .inner-wrap {
        position: fixed;
        left: 200px;
        bottom: 60px;
        width: 100%;
        height: 320px;
        background: #fff;
        z-index: 333;
        overflow: auto;
        padding: 0 20px;
        .list-wrap {
            margin-right: -16px;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .wrap{
                margin-right: 16px;
                width: 306px;
            }
        }
    }
    .unfold {
        left: 0;
    }
}
</style>
